<template>
  <div :class="$style.control">
    <div :class="$style.box">
      <div :class="$style.title">选择运城文旅云</div>
      <div :class="$style.avatar">
        <img :src="user.avatar" />
        <span @click="loginOut()">退出登录</span>
      </div>

      <div :class="$style.flex">
        <div v-for="item in data" :key="item.cloud_id" :class="$style.item" @click="loginIn(item)">
          <div :class="$style.head" />
          <div :class="$style.middle">
            <img :src="item.cloud_logo" />
            <div>
              <p :class="$style.name">{{ item.cloud_name }}</p>
              <p :class="$style.role">
                <span :class="item.status ? $style.stateS : $style.stateE" />
                {{ item.role_name }}
              </p>
            </div>
          </div>
          <div :class="$style.foot">
            <span class="tag">
              {{ item.version_name }}
            </span>
            <span :class="$style.time"> 有效期至：{{ item.version_expire }} </span>
          </div>
        </div>
      </div>

      <div :class="$style.add" @click="onPerfect()">
        <span>
          创建新的文化云
          <i class="ri-arrow-right-s-line" />
        </span>
      </div>
    </div>
    <div :class="$style.footer">
      <a :href="official.web_site" class="primary" target="_blank">{{ official.official_name }}</a
      >提供技术支持
      <span class="ml-5">
        ©{{ year }}{{ official.principal_name }}
        <a class="default-link ml-5" href="https://beian.miit.gov.cn/" target="_blank">{{ official.icp_no }}</a>
      </span>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import { systemService } from '@/service'

const service = new systemService()

export default {
  name: 'control',
  data() {
    return {
      data: [],
      year: 2022, // 当前年份
    }
  },
  computed: {
    ...mapState(['user', 'official']),
  },
  created() {
    this.year = new Date().getFullYear()
    this.getInfo()
  },
  methods: {
    ...mapMutations(['LOGOUT', 'LOGIN']),
    loginOut() {
      this.LOGOUT()
    },
    async loginIn(item) {
      try {
        const { data } = await service.getToken({
          cloud_id: item.cloud_id,
        })
        this.LOGIN(data)
      } catch (e) {}
    },
    async getInfo() {
      try {
        const { data } = await service.getUSerCloud()
        this.data = data.cloud
      } catch (error) {}
    },
    onPerfect() {
      if (this.data.length > 4) {
        this.$message.error('最多只能创建5个文化云')
        return
      }
      this.$router.push({
        name: 'perfect',
      })
    },
  },
}
</script>

<style lang="less" module>
@oss: 'https://minio.ycwly.com/cultural-cloud';

.control {
  position: relative;
  height: 100vh;
  min-height: 800px;
  overflow: auto;
  background-color: #f5fcfd;
  background-image: url('@{oss}/manage/media/assets/login_bg.jpg');
  background-position: 50%;
  background-size: 2048px 1063px;

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    width: 880px;
    height: 600px;
    overflow: hidden;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    transform: translate(-50%, -50%);
    padding: 30px;

    .title {
      font-size: 18px;
      margin-bottom: 30px;
    }

    .avatar {
      position: absolute;
      right: 30px;
      top: 30px;
      display: flex;
      align-items: center;
      color: @primary-color;
      font-size: 14px;

      img {
        width: 27px;
        height: 27px;
        border-radius: 100%;
        margin-right: 10px;
      }

      span {
        cursor: pointer;
      }
    }

    .flex {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;

      .item {
        width: 260px;
        height: 150px;
        border-radius: 6px;
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        cursor: pointer;

        .head {
          width: 100%;
          height: 8px;
          background-color: @primary-color;
        }

        .middle {
          display: flex;
          width: 100%;
          padding: 20px;

          img {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            margin-right: 10px;
          }

          .name {
            font-size: 18px;
            margin-bottom: 4px;
          }

          .role {
            font-size: 13px;
            margin-bottom: 0;
            color: #5c5c5c;
          }

          .state-s {
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background-color: @primary-color;
            margin-right: 5px;
            display: inline-block;
            position: relative;
            top: -3px;
          }

          .state-e {
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background-color: @error-color;
            margin-right: 5px;
            display: inline-block;
            position: relative;
            top: -3px;
          }
        }

        .foot {
          padding: 0 20px;
          font-size: 12px;
          color: #5c5c5c;
          line-height: 42px;

          .time {
            float: right;
          }
        }
      }
    }

    .flex::after {
      width: 30%;
      content: '';
    }
    .add {
      position: absolute;
      bottom: 30px;
      left: calc(50% - 58px);
      font-size: 12px;
      color: @primary-color;
      cursor: pointer;

      i {
        font-size: 18px;
        vertical-align: -4px;
        color: @primary-color;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    color: #5c5c5c;
    font-size: 12px;
    text-align: center;
  }
}
</style>
